<template>
  <div class="company_details container" v-loading="loading">
    <topBasic class="mb20" :details="details" />

    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="工程基本信息" name="工程基本信息" lazy>
        <basicInfo :details="details" />
      </el-tab-pane>

      <el-tab-pane label="招投标信息" name="招投标信息" lazy>
        <biddingInfo :basicId="basicId" />
      </el-tab-pane>

      <el-tab-pane label="合同登记信息" name="合同登记信息" lazy>
        <contractInfo :basicId="basicId" />
      </el-tab-pane>

      <el-tab-pane label="施工图审查" name="施工图审查" lazy>
        <constDrawReview :basicId="basicId" />
      </el-tab-pane>

      <el-tab-pane label="施工许可" name="施工许可" lazy>
        <constPermit :basicId="basicId" />
      </el-tab-pane>

      <el-tab-pane label="竣工验收" name="竣工验收" lazy>
        <completeAccept :basicId="basicId" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/* 公司详情 */
import { getSikuyiBasic } from "@/api/fourthLibrary.js";
import topBasic from "./components/topBasic.vue"; // 工程基本信息
import basicInfo from "./components/basicInfo.vue"; // 工程基本信息
import biddingInfo from "./components/biddingInfo.vue"; // 招投标信息
import contractInfo from "./components/contractInfo.vue"; // 合同登记信息
import constDrawReview from "./components/constDrawReview.vue"; // 施工图审查
import constPermit from "./components/constPermit.vue"; // 施工许可
import completeAccept from "./components/completeAccept.vue"; // 竣工验收

export default {
  components: {
    topBasic,
    basicInfo,
    biddingInfo,
    contractInfo,
    constDrawReview,
    constPermit,
    completeAccept,
  },

  data() {
    return {
      activeName: "工程基本信息",
      details: { certDetailDtoList: [] },
      basicId: "",
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取基础数据
    async getData() {
      if (this.loading) return;
      this.loading = true;
      const { code, data, msg } = await getSikuyiBasic({
        basicId: this.basicId,
      });
      if (code !== 0) return this.$message.error(msg);
      this.details = data;
      this.loading = false;
    },

    /* 操作 */
    // tab的点击事件
    handleTabClick(tab) {
      this.activeName = tab.name;
    },

    /* 初始化 */
    __init__() {
      this.basicId = this.$route.params.id || this.basicId;
      this.getData();
      this.activeName = "工程基本信息";
    },
  },

  watch: {
    // 监听,当路由发生变化的时候执行
    // $route: {
    //   // $route可以用引号，也可以不用引号
    //   handler(to, from) {
    //     let name = this.$route.params.name;
    //     this.activeName = name;
    //   },
    //   deep: true, // 深度观察监听
    //   immediate: true, // 第一次初始化渲染就可以监听到
    // },
  },

  created() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.company_details {
  /deep/.el-tabs__header,
  /deep/.el-tabs__content {
    background-color: #fff;
    padding: 20px;
  }

  /deep/.el-tabs__header {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    z-index: 2000;
    top: 70px;
  }

  /deep/.el-collapse-item__content {
    padding-bottom: 0px;
  }
  /deep/.el-descriptions__body {
    padding: 20px;
    background-color: #f5faff;
  }
}
</style>
